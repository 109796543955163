import { useState, useCallback, useEffect } from "react"
import { useAuth, APIError } from "../../../../../app/modules/auth"
import { getAllPassareAcquaintances, PassareAcquaintance } from "../../../../../mogul/core/_requests"
import { LeadHeader, useLeadDetail } from "../DebugAllLeads"
import { formatLeadItemDate } from "../DebugPipedrive"

export function PassareAcquaintancesTable() {

    const {
        currentUser
    } = useAuth()

    const {
        accounts
    } = useLeadDetail()

    const isAdmin = !!currentUser?.is_admin

    const [showLeadsRegardlessofGHLStatus, setShowLeadsRegardlessofGHLStatus] = useState(isAdmin ? false : true)

    const [filteringByLocationUniqueIdentifier, setFilteringByLocationUniqueIdentifier] = useState<string | null>(null)

    const [loading, setLoading] = useState(true);
    const [errorLoading, setErrorLoading] = useState<string | null>(null);

    const [results, setResults] = useState<PassareAcquaintance[]>([])
    const [totalResults, setTotalResults] = useState(0)

    const loadList = useCallback(async () => {
        // We reload on viewingTab for now
        setErrorLoading(null)
        setLoading(true)
        try {
            const {
                passare_acquaintances: result,
                total_passare_acquaintances: totalResult
            } = await getAllPassareAcquaintances({
                limit: 100,
                filteringByLocationUniqueIdentifier
            })
            setResults(result)
            setTotalResults(totalResult)
        } catch (exception) {
            console.error(exception)
            const errorMessage =
                exception instanceof APIError ? exception.message : 'Failed to load leads'
            setErrorLoading(errorMessage)
        } finally {
            setLoading(false)
        }
    }, [filteringByLocationUniqueIdentifier])

    useEffect(() => {
        loadList()
    }, [loadList])

    if (loading) {
        return <div className='p-10'>
            <div className="spinner-border" role="status">
                <span className="sr-only">Loading...</span>
            </div>
        </div>
    }

    if (errorLoading) {
        return <div className='p-10'>
            <div className="alert alert-danger" role="alert">
                {errorLoading}
            </div>
            <div className='mt-5'>
                <button className='btn btn-primary btn-lg' onClick={loadList}>
                    Retry
                </button>
            </div>
        </div>
    }

    return <div>
        <LeadHeader
            title={totalResults === 1 ? '1 Passare Lead' : `${totalResults} Passare Leads`}
            subtitle={!isAdmin ? '' : ''}
            showLeadsRegardlessofGHLStatus={showLeadsRegardlessofGHLStatus}
            setShowLeadsRegardlessofGHLStatus={setShowLeadsRegardlessofGHLStatus}
            setFilteringByLocation={setFilteringByLocationUniqueIdentifier}
        />
        <div className='px-10'>
            <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                {/* begin::Table head */}
                <thead>
                    <tr className='fw-bold text-muted'>
                        <th className='min-w-150px'>When Created?</th>
                        <th className='min-w-150px'>Last Updated</th>
                        <th className='min-w-150px'>Organization</th>
                        <th>Status</th>
                        <th>Deceased</th>
                        <th>Person</th>
                        <th>Relationship</th>
                        <th className='min-w-150px'>Phone Numbers</th>
                        <th>Email</th>
                    </tr>
                </thead>
                {/* end::Table head */}
                {/* begin::Table body */}
                <tbody>
                    {results.map((acquaintance: PassareAcquaintance) => {
                        return <tr key={`event-administrator-${acquaintance.unique_identifier}`} data-unique-identifier={acquaintance.unique_identifier}>
                            <td>
                                {formatLeadItemDate(acquaintance.inserted_at)}
                            </td>
                            <td>
                                {formatLeadItemDate(acquaintance.updated_at, true)}
                            </td>
                            <td>
                                {acquaintance.passare_organization.organization_name || 'Unknown Organization'}
                            </td>
                            {acquaintance.case_item.public_data.case_result ? <>
                                <td>
                                    {acquaintance.case_item.public_data.case_result.case_status || '?'}
                                </td>
                                <td>
                                    {acquaintance.case_item.public_data.case_result.decedent_person ? <>
                                        {acquaintance.case_item.public_data.case_result.case_url ? <>
                                            <a href={acquaintance.case_item.public_data.case_result.case_url} target='_blank' rel='noreferrer'>
                                                {acquaintance.getDecedentFullName()}
                                            </a>
                                        </> : <>
                                            {acquaintance.getDecedentFullName()}
                                        </>}
                                    </> : <>
                                        {acquaintance.case_item.public_data.case_result.decedent_animal ? <>
                                            {acquaintance.case_item.public_data.case_result.case_url ? <>
                                                <a href={acquaintance.case_item.public_data.case_result.case_url} target='_blank' rel='noreferrer'>
                                                    {acquaintance.getDecedentFullName()}
                                                </a>
                                            </> : <>
                                                {acquaintance.getDecedentFullName()}
                                            </>}
                                        </> : <>
                                            {`Error with unique_identifier: ${acquaintance.unique_identifier} - No decedent found`}
                                        </>}
                                    </>}
                                </td>
                            </> : <>
                                <td>-</td>
                                <td>-</td>
                            </>}
                            <td>
                                {acquaintance.public_data.acquaintance_result ? <>
                                    {`${acquaintance.public_data.acquaintance_result.first_name} ${acquaintance.public_data.acquaintance_result.last_name}`}
                                </> : '-'}
                            </td>
                            <td>
                                {acquaintance.public_data.acquaintance_result ? <>
                                    {acquaintance.public_data.acquaintance_result.relationship || '-'}
                                </> : '-'}
                            </td>
                            <td>
                                {acquaintance.public_data.phone_numbers && (acquaintance.public_data.phone_numbers.home_phone || acquaintance.public_data.phone_numbers.mobile_phone || acquaintance.public_data.phone_numbers.work_phone) ? <>
                                    {acquaintance.public_data.phone_numbers.home_phone && <div>
                                        Home: {acquaintance.public_data.phone_numbers.home_phone}
                                    </div>}
                                    {acquaintance.public_data.phone_numbers.mobile_phone && <div>
                                        Mobile: {acquaintance.public_data.phone_numbers.mobile_phone}
                                    </div>}
                                    {acquaintance.public_data.phone_numbers.work_phone && <div>
                                        Work: {acquaintance.public_data.phone_numbers.work_phone}
                                    </div>}
                                </> : '-'}
                            </td>
                            <td>
                                {acquaintance.public_data.email_address ? <>
                                    {acquaintance.public_data.email_address}
                                </> : '-'}
                            </td>
                        </tr>
                    })}
                </tbody>
                {/* end::Table body */}
            </table>
        </div>
    </div>

}