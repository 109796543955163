/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState, useEffect, useCallback, useMemo } from 'react'
import {
  DateTime
} from 'luxon'

import { getPassareRequests, DashboardPassareData, PassareRequestData } from '../../../../mogul/core/_requests'
import { APIError } from '../../../../app/modules/auth'
import MogulHeader from '../../../../mogul/components/MogulHeader'

type Props = {
}

const formatPassareRequestDate = (passareRequest) => {
  try {
    const dateTime = DateTime.fromISO(passareRequest.inserted_at)
      .setZone('America/New_York');
    const isDST = dateTime.isInDST; // Check if the date is in daylight saving time
    const tzAbbreviation = isDST ? 'EDT' : 'EST'; // Choose abbreviation based on DST
    return dateTime.toFormat(`yyyy-MM-dd HH:mm '${tzAbbreviation}' (EEEE)`);
  } catch (e) {
    return 'Invalid DateTime';
  }
}


function DebugPassareRequestsRow({ passareRequest }: { passareRequest: PassareRequestData }) {
  console.log('passareRequest', passareRequest.request_body)

  return <tr key={`passare-req-${passareRequest.unique_identifier}`}>
    <td>
      {formatPassareRequestDate(passareRequest)}
    </td>
    <td>
      {passareRequest.trigger_type}<br />
      {passareRequest.event_id}
    </td>
    <td>
      <pre>
        {JSON.stringify(passareRequest.parsed_payload, null, 2)}
      </pre>
    </td>
    <td>
      {passareRequest.email_address}
    </td>
    <td>
      <pre>
        {passareRequest.phone_numbers ? JSON.stringify(passareRequest.phone_numbers, null, 2) : ''}
      </pre>
    </td>
    <td>
      <pre>
        {passareRequest.address ? JSON.stringify(passareRequest.address, null, 2) : ''}
      </pre>
    </td>
  </tr>
}

function Header({
  loadInitialRequestData,
  includeTokenRequests,
  setIncludeTokenRequests
}: {
  loadInitialRequestData: () => void,
  includeTokenRequests: boolean,
  setIncludeTokenRequests: (value: boolean) => void
}) {
  const [viewingSettings, setViewingSettings] = useState(false)

  return <>
    <div className='card-header border-0 pt-5'>
      <h3 className='card-title align-items-start flex-column'>
        <span className='card-label fw-bold fs-3 mb-1'>
          Passare Requests
        </span>
        {false && <span className='text-muted mt-1 fw-semibold fs-7'>
          Subtitle goes here
        </span>}
      </h3>
      <div
        className='card-toolbar'
      >
        <button
          className='btn btn-sm btn-light-primary'
          onClick={() => setViewingSettings(!viewingSettings)}
        >
          Settings
        </button>
      </div>
    </div>
    {viewingSettings && <div>
      <div className='card-body'>
        <div>
          <div className='form-check form-switch form-check-custom form-check-solid'>
            <input
              className='form-check-input'
              type='checkbox'
              id='includeTokenRequests'
              checked={includeTokenRequests}
              onChange={(e) => setIncludeTokenRequests(e.target.checked)}
            />
            <label
              className='form-check-label'
              htmlFor='includeTokenRequests'
            >
              Include Token Requests
            </label>
          </div>
        </div>
      </div>
    </div>}
  </>
}

export const DebugPassareRequests: React.FC<Props> = () => {
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  const [dashboardResponse, setDashboardResponse] = useState<DashboardPassareData | null>(null)
  const [additionalRequests, setAdditionalRequests] = useState<PassareRequestData[]>([])

  const [loadedPage, setLoadedPage] = useState(1)
  const [paginationError, setPaginationError] = useState<string | null>(null)
  const [hasMore, setHasMore] = useState(true)

  const [includeTokenRequests, setIncludeTokenRequests] = useState(false)

  const loadInitialRequestData = useCallback(async () => {
    setError(null)
    setLoading(true)
    try {
      const result = await getPassareRequests({
        limit: 100,
        // includeTokenRequests
      })
      setDashboardResponse(result)
    } catch (exception) {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load Passare requests'
      setError(errorMessage)
    } finally {
      setLoading(false)
    }
  }, [includeTokenRequests])

  const fetchMoreRequestData = useCallback(async () => {
    /*
    getHighLevelRequestsWithPage({
      page: loadedPage + 1,
      limit: 100,
      includeTokenRequests
    }).then((result) => {
      setPaginationError(null)
      if (result.length > 0) {
        setAdditionalRequests([...additionalRequests, ...result])
        setLoadedPage(loadedPage + 1)
        setHasMore(result.length === 100)
      } else {
        setHasMore(false)
      }
    }).catch((exception) => {
      console.error(exception)
      const errorMessage =
        exception instanceof APIError ? exception.message : 'Failed to load Passare requests'
      setPaginationError(errorMessage)
    })
      */
  }, [additionalRequests, includeTokenRequests, loadedPage])

  useEffect(() => {
    loadInitialRequestData()
  }, [loadInitialRequestData])

  if (loading) {
    return <>
      <span className='indicator-progress' style={{ display: 'block' }}>
        Loading...{' '}
        <span className='spinner-border spinner-border-sm align-middle ms-2'></span>
      </span>
    </>
  }

  if (error) {
    return <div>
      <div className="alert alert-danger" role="alert">
        {error}
      </div>
      <div className='mt-5'>
        <button className='btn btn-primary btn-lg' onClick={loadInitialRequestData}>
          Retry
        </button>
      </div>
    </div>
  }

  return (
    <>
      <MogulHeader title='Passare Requests' />
      <div className='row gy-5 g-xl-8' style={{ height: '100%' }}>
        <div className='col-xxl-12'>
          <div className={`card card-xxl-stretch mb-5 mb-xl-8`}>

            <Header
              loadInitialRequestData={loadInitialRequestData}
              includeTokenRequests={includeTokenRequests}
              setIncludeTokenRequests={setIncludeTokenRequests}
            />

            {/* end::Header */}
            {/* begin::Body */}
            <div className='card-body py-3'>
              {/* begin::Table container */}
              <div className='table-responsive'>
                {/* begin::Table */}
                <table className='table table-row-dashed table-row-gray-300 align-middle gs-0 gy-4'>
                  {/* begin::Table head */}
                  <thead>
                    <tr className='fw-bold text-muted'>
                      <th className='min-w-150px'>Date</th>
                      <th className='min-w-150px'>Trigger Type</th>
                      <th className='min-w-150px'>Parsed Body</th>
                      <th className='min-w-150px'>Email</th>
                      <th className='min-w-150px'>Phone Numbers</th>
                      <th className='min-w-150px'>Address</th>
                    </tr>
                  </thead>
                  {/* end::Table head */}
                  {/* begin::Table body */}
                  <tbody>
                    {[
                      ...(dashboardResponse?.passare_requests || []),
                      ...additionalRequests
                    ].map((passareRequest) => {
                      return <DebugPassareRequestsRow key={`passare-req-${passareRequest.unique_identifier}`} passareRequest={passareRequest} />
                    })}
                    {hasMore && <tr>
                      <td colSpan={8}>
                        <button onClick={fetchMoreRequestData} className='btn btn-primary'>
                          Load More
                        </button>
                      </td>
                    </tr>}
                  </tbody>
                  {/* end::Table body */}
                </table>
                {/* end::Table */}
              </div>
              {/* end::Table container */}
            </div>
            {/* begin::Body */}
          </div>
        </div>
      </div>
    </>
  )
}